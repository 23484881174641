import styled, { css } from 'styled-components'
import React from 'react'
import { AutoComplete, Card, Table } from 'antd'
import { borderRadius, color, height, padding, typography } from '@libs/theme/mixins'

const NewOrderAutocomplete = styled(AutoComplete)`
	flex: 1;
`

const NewOrderAutocompleteOption = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${height('xs')};
`

const NewOrderAutocompleteDepartment = styled.div`
	${typography('sm')}
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-weight: 500;
	height: ${height('xs')};
	padding: ${padding(0, 'sm')};
	background: ${color(['gray', '100'])};
	border: 1px solid ${color(['primary', '200'])};
	border-radius: ${borderRadius('sm')};
`

const SalesCardTitle = styled.div`
	// Just to make sure title is rendered but not visible (in order to align the cards)
	visibility: hidden;
`

const SalesSection = styled(Card)<{ $imageClipId: string }>`
	position: relative;

	.ant-card-body {
		overflow: hidden;
		position: relative;
		padding: 0;
		padding-left: 118px; // 200 - 82
		display: flex;
		align-items: center;
	}

	img {
		width: 200px;
		height: 200px;
		position: absolute;
		top: -31px;
		left: -82px;
		clip-path: url(#${({ $imageClipId }) => $imageClipId});
		filter: grayscale(100%);
	}
`

const TableWithClickableRow = styled(props => <Table {...props} />)`
	${({ onRow }) =>
		!!onRow &&
		css`
			.ant-table-row {
				cursor: pointer;
			}
		`}
`

export default {
	NewOrderAutocomplete,
	NewOrderAutocompleteOption,
	NewOrderAutocompleteDepartment,
	SalesSection,
	SalesCardTitle,
	TableWithClickableRow,
}
