import React from 'react'
import { Skeleton } from 'antd'
import { Grid } from '@libs/components'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'

const OrderFlowCards = () => {
	const { t } = useLocalization()

	return (
		<>
			<Grid.Col xs={24} sm={8}>
				<Styled.SkeletonCard title={t('orders.pages.new.flowSelection.typeTitle')}>
					<Skeleton.Image />
				</Styled.SkeletonCard>
			</Grid.Col>
			<Grid.Col xs={24} sm={8}>
				<Styled.SkeletonCard title={<>&nbsp;</>}>
					<Skeleton.Image />
				</Styled.SkeletonCard>
			</Grid.Col>
			<Grid.Col xs={24} sm={8}>
				<Styled.SkeletonCard title={<>&nbsp;</>}>
					<Skeleton.Image />
				</Styled.SkeletonCard>
			</Grid.Col>
		</>
	)
}

export default { OrderFlowCards }
