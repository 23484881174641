import React, { useMemo, useState, useEffect } from 'react'
import { AutoComplete, Card, Form, message, Radio, Select, Typography } from 'antd'
import { SelectProps } from 'formik-antd'
import { useAuth } from 'modules/auth/hooks'
import { Grid, Container, Anchor } from '@libs/components'
import { useCreateOrderCaseAutocomplete } from '../../orders/hooks'
import { forgetNewOrderState, getInitialNewOrderState, useNewOrderContext } from '../../orders/context'
import { useLocalization } from '@libs/localization'
import Styled from './Styled'
import { DepartmentApi, IPackage, PackagesApi } from '@libs/api'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import Skeleton from './Skeleton'
import { useScreenMatch } from '@libs/theme'
import { useRouter } from 'next/router'
import appRoutes from 'app.routes'
import { useAppEvents } from 'modules/common/app.events'
import Link from 'next/link'
import { LAST_DEPARTMENT_ID } from '../DashboardPageContent/consts'
import { OfficeSvg, PropertySvg, RealtorSvg } from '../DashboardPageContent/resources'

const NewOrderFlowSelection = () => {
	const router = useRouter()
	const appEvents = useAppEvents()
	const { user } = useAuth()
	const [selectedPackageType, selectPackageType] = useState<IPackage['type']>()
	const lastDepartmentId = useMemo(
		() => (typeof localStorage !== 'undefined' ? localStorage.getItem(LAST_DEPARTMENT_ID) : undefined),
		[]
	)
	const [{ step, status }, setOrderState] = useNewOrderContext()
	const [selectedDepartmentId, selectDepartmentId] = useState(lastDepartmentId || user?.branches?.[0]?.id)

	const foundBranchId = useMemo(
		() =>
			user?.isInternalUser ? user?.branches.find(({ id }) => id === selectedDepartmentId)?.id : selectedDepartmentId,
		[user, selectedDepartmentId]
	)

	const { data: packages = [], isLoading } = useQuery(
		[QUERIES.packages, foundBranchId],
		() => PackagesApi.getPackages(foundBranchId!),
		{
			enabled: !!foundBranchId,
		}
	)

	const { data: products = [] } = useQuery(
		[QUERIES.individualProducts, foundBranchId],
		() => PackagesApi.getEmptyPackageProducts(foundBranchId!),
		{
			enabled: !!foundBranchId,
		}
	)

	const { data: canCreateOrder = [] } = useQuery(
		[QUERIES.canCreateOrderCheck, foundBranchId],
		() => DepartmentApi.getCanBranchCreateOrder(foundBranchId!),
		{
			enabled: !!foundBranchId,
		}
	)

	const departmentOptions = useMemo<SelectProps['options']>(
		() =>
			(user?.branches || []).map(userDepartment => ({
				value: userDepartment.id,
				label: userDepartment.name,
			})),
		[user]
	)

	const selectedBranchId = useMemo(
		() => (user?.branches || []).find(({ id }) => id === selectedDepartmentId)?.id,
		[selectedDepartmentId, user]
	)

	const caseAutocomplete = useCreateOrderCaseAutocomplete(selectedBranchId)
	const { t } = useLocalization()

	const typeOptions = useMemo(() => {
		const uniqueAvailablePackageTypes = [...new Set(packages.map(({ type }) => type))] // unique values

		return [
			{
				title: t('orders.pages.new.flowSelection.typeTitle'),
				SourceSvg: PropertySvg,
				flow: 'property',
				type: t('common.terms.property'),
			},
			{
				SourceSvg: RealtorSvg,
				flow: 'realtor',
				type: t('common.terms.realtor'),
			},
			{
				SourceSvg: OfficeSvg,
				flow: 'office',
				type: t('common.terms.office'),
			},
		].filter(({ flow }) => uniqueAvailablePackageTypes.includes(flow as IPackage['type']))
	}, [t, packages])

	useEffect(() => {
		if (typeOptions.length > 0) {
			// initialize with 1st selected
			selectPackageType(typeOptions[0].flow as IPackage['type'])
		}
	}, [typeOptions])

	const match = useScreenMatch()

	const isOrderCardSmall = match.map({ xxs: false, sm: true, lg: false })

	const reference = useMemo(() => {
		switch (selectedPackageType) {
			case 'realtor':
				return {
					label: t('orders.pages.new.flowSelection.personalEmail'),
					placeholder: t('common.placeholders.personalEmail'),
					title: t('orders.pages.new.flowSelection.orderPortrait'),
				}
			case 'office':
				return {
					label: t('orders.pages.new.flowSelection.officeEmail'),
					placeholder: t('common.placeholders.officeEmail'),
					title: t('orders.pages.new.flowSelection.orderShopPhotos'),
				}
			default:
				return {
					label: t('common.terms.case'),
					placeholder: t('common.placeholders.caseAddressOrNumber'),
					title: t('orders.pages.new.flowSelection.orderPhotography'),
				}
		}
	}, [selectedPackageType, t])

	return (
		<Grid.Row>
			<Grid.Col xs={24}>
				<Grid.Row gutter={['sm', 'md']}>
					{isLoading ? (
						<Skeleton.OrderFlowCards />
					) : (
						<>
							{typeOptions.length > 1 &&
								typeOptions.map(({ title, SourceSvg, type, flow }) => (
									<Grid.Col key={flow} xs={24} sm={8}>
										<Styled.OrderType
											title={title ?? match.map({ xxs: undefined, sm: <>&nbsp;</> })}
											selected={flow === selectedPackageType}
											$smallSize={isOrderCardSmall}
											onClick={() => {
												if (selectedPackageType !== flow) selectPackageType(flow as typeof selectedPackageType)
											}}
										>
											<Container display="flex" alignItems="center" justifyContent="center" space="sm">
												<SourceSvg />
											</Container>
											<Styled.OrderTypeFooter $smallSize={isOrderCardSmall}>
												<Radio checked={flow === selectedPackageType}>
													{match.gte('xxl') ? (
														<Typography.Title level={5}>{type}</Typography.Title>
													) : (
														<Typography.Text strong>{type}</Typography.Text>
													)}
												</Radio>
											</Styled.OrderTypeFooter>
										</Styled.OrderType>
									</Grid.Col>
								))}
							{typeOptions.length === 0 && (
								<Container
									display="flex"
									alignItems="center"
									justifyContent="center"
									margin={match.lte('xs') ? 'auto' : { left: 'xxs', right: 'xxs' }}
								>
									<Typography.Title level={4}>{t('orders.pages.new.flowSelection.noPackages')}</Typography.Title>
								</Container>
							)}
						</>
					)}
				</Grid.Row>
			</Grid.Col>

			<Grid.Col xs={24}>
				<Card
					title={reference.title}
					extra={
						typeof step === 'number' &&
						status !== 'done' && (
							<Link href={appRoutes.newOrder()} passHref>
								<Anchor underline>{t('dashboard.order.continue')}</Anchor>
							</Link>
						)
					}
				>
					<Form layout={'vertical'}>
						<Grid.Row gutter={['sm', 'md']} align={'bottom'}>
							<Grid.Col xs={24} lg={12} xxl={9}>
								<Form.Item label={t('common.terms.department')}>
									<Select
										value={selectedDepartmentId}
										options={departmentOptions}
										showArrow
										showSearch
										filterOption={(input, option) => option?.label?.toString().toLowerCase().includes(input) ?? false}
										onChange={newDepartmentId => {
											// reset value on autocomplete
											caseAutocomplete.setQuery('')
											caseAutocomplete.setSelectedCaseId('')
											selectDepartmentId(newDepartmentId)
											localStorage.setItem(LAST_DEPARTMENT_ID, newDepartmentId)
											setOrderState(prevState => ({ ...prevState, departmentId: newDepartmentId }))
										}}
									/>
								</Form.Item>
							</Grid.Col>
							<Grid.Col xs={24} lg={12} xxl={9}>
								<Form.Item label={reference.label}>
									<AutoComplete
										value={caseAutocomplete.query}
										onSearch={value => {
											const regExp = new RegExp(/[-_ @.,A-Za-zæøåÆØÅÖö0-9]+$/)
											if (regExp.test(value) || !value) {
												caseAutocomplete.setQuery(value)
											}
										}}
										onSelect={value => {
											caseAutocomplete.setQuery(value)
											caseAutocomplete.setSelectedCaseId(value)
										}}
										placeholder={reference.placeholder}
										onPopupScroll={caseAutocomplete.onPopupScroll}
									>
										{caseAutocomplete.options.map(({ value, label }, index) => (
											<AutoComplete.Option key={index} value={value}>
												{label}
											</AutoComplete.Option>
										))}
									</AutoComplete>
								</Form.Item>
							</Grid.Col>
							<Grid.Col flex={'auto'}>
								<Styled.SubmitButton
									shape="round"
									type="primary"
									disabled={!caseAutocomplete.selectedCaseId || !canCreateOrder}
									onClick={async () => {
										if (canCreateOrder && selectedDepartmentId && selectedBranchId && (selectedPackageType || products.length > 0)) {
											forgetNewOrderState()
											appEvents.dispatch('orders.new.context.clear')
											const initialOrder = await getInitialNewOrderState({
												case: caseAutocomplete.selectedCase,
												departmentId: selectedDepartmentId,
												branchId: selectedBranchId,
												explicitlySelectedDepartmentId: true,
												packageType: selectedPackageType || 'property',
											})
											await router.push(appRoutes.newOrder())
											setOrderState(initialOrder)
										} else {
											message.error(t('orders.pages.new.flowSelection.error'))
										}
									}}
								>
									{t('orders.pages.new.flowSelection.submit')}
								</Styled.SubmitButton>
							</Grid.Col>
						</Grid.Row>
					</Form>
				</Card>
			</Grid.Col>
		</Grid.Row>
	)
}

export default NewOrderFlowSelection
