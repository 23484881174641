import React from 'react'
import Page from 'modules/common/Page'
import DashboardPageContent from 'modules/dashboard/DashboardPageContent'

const DashboardPage = () => (
	<Page guard titleKey="dashboard">
		<DashboardPageContent />
	</Page>
)

export default DashboardPage
