import { Anchor, Container, Grid } from '@libs/components'
import { Card } from 'antd'
import appRoutes from 'app.routes'
import Link from 'next/link'
import React, { FC } from 'react'
import Styled from './Styled'
import { useCasesTable } from 'modules/cases/hooks'
import { useTasksTable } from 'modules/tasks/hooks'
import { IDatahubCase, IDatahubTask, OrderStatus, TaskStatus } from '@libs/api'
import { useRouter } from 'next/router'
import { useLocalization } from '@libs/localization'
import { useScreenMatch, ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME } from '@libs/theme'
import NewOrderFlowSelection from 'modules/dashboard/NewOrderFlowSelection'

const DashboardPageContent: FC = () => {
	const router = useRouter()
	const { t } = useLocalization()

	// unfinished tasks
	const tasks = useTasksTable(
		{
			pageSize: 5,
			status: [TaskStatus.Open, TaskStatus.Partial],
			orderBy: 'createdAt',
			orderByDirection: 'descend',
		},
		'basic'
	)

	// cases with deliveries
	const cases = useCasesTable(
		{
			pageSize: 5,
			status: [OrderStatus.Delivered],
			orderBy: 'ModifiedDate',
			orderByDirection: 'descend',
		},
		'basic'
	)

	const match = useScreenMatch()

	return (
		<Container padding={['xl', 0]}>
			<NewOrderFlowSelection />
			<Grid.Row>
				<Grid.Col span={24}>
					<Card
						size={match.map({ xxs: 'small', sm: 'default' })}
						title={t('dashboard.tasks.title')}
						extra={
							<Link href={appRoutes.tasks.list()} passHref>
								<Anchor underline>{t('dashboard.tasks.seeAllLink')}</Anchor>
							</Link>
						}
					>
						<Styled.TableWithClickableRow
							className={ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME}
							rowKey={'id'}
							columns={tasks.columns}
							dataSource={tasks.dataSource}
							pagination={false}
							locale={{ emptyText: tasks.isLoading ? <div className="ant-empty ant-empty-normal" /> : undefined }}
							loading={tasks.isLoading}
							onRow={(taskObj: IDatahubTask) => ({
								onClick: () => tasks.openTask(taskObj),
							})}
						/>
					</Card>
				</Grid.Col>
			</Grid.Row>
			<Grid.Row>
				<Grid.Col span={24}>
					<Card
						size={match.map({ xxs: 'small', sm: 'default' })}
						title={t('dashboard.cases.title')}
						extra={
							<Link href={appRoutes.cases.list()} passHref>
								<Anchor underline>{t('dashboard.cases.seeAllLink')}</Anchor>
							</Link>
						}
					>
						<Styled.TableWithClickableRow
							className={ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME}
							rowKey={'id'}
							columns={cases.columns}
							dataSource={cases.dataSource}
							pagination={false}
							locale={{ emptyText: cases.isLoading ? <div className="ant-empty ant-empty-normal" /> : undefined }}
							loading={cases.isLoading}
							onRow={({ id }: IDatahubCase) => ({
								onClick: () => id && router.push(appRoutes.cases.details(id)),
							})}
						/>
					</Card>
				</Grid.Col>
			</Grid.Row>
		</Container>
	)
}

export default DashboardPageContent
