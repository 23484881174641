import React, { FC } from 'react'

export const SalesAvatarMask: FC<{ id: string }> = ({ id }) => (
	<svg>
		<defs>
			<clipPath id={id}>
				<path d="M82.218 31.17l57.981.008 54.313 60.03a24.551 24.551 0 01-3.635 34.53L82.193 199.116z" />
			</clipPath>
		</defs>
	</svg>
)

export const OfficeSvg = () => (
	<svg width="121" height="112" viewBox="0 0 121 112" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1_248)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.3418 93.3903V39.709H19.3418V93.391C19.3418 93.816 19.4254 94.2375 19.588 94.6301C19.7506 95.0228 19.989 95.3796 20.2895 95.6801C20.59 95.9806 20.9467 96.219 21.3394 96.3816C21.732 96.5443 22.1528 96.628 22.5778 96.628H97.7338C98.1588 96.628 98.5796 96.5443 98.9722 96.3816C99.3649 96.219 99.7216 95.9806 100.022 95.6801C100.323 95.3796 100.561 95.0228 100.724 94.6301C100.886 94.2375 100.97 93.8167 100.97 93.3917V39.709H115.97V93.3903C115.97 93.3905 115.97 93.3901 115.97 93.3903C115.97 95.785 115.498 98.1569 114.582 100.369C113.666 102.582 112.323 104.593 110.629 106.286C108.936 107.98 106.925 109.323 104.713 110.24C102.5 111.156 100.129 111.628 97.7338 111.628H22.5778C20.1829 111.628 17.8114 111.156 15.5988 110.24C13.3862 109.323 11.3758 107.98 9.6824 106.286C7.989 104.593 6.64578 102.582 5.72944 100.369C4.81319 98.1569 4.34167 95.785 4.3418 93.3903C4.3418 93.3901 4.3418 93.3905 4.3418 93.3903Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M75.9883 5.46174e-08L105.703 5.31455e-07C105.704 5.31455e-07 105.704 5.46174e-08 105.705 5.46174e-08C105.705 5.46174e-08 105.705 5.46174e-08 105.705 5.46174e-08C108.238 -0.00025553 110.69 0.896513 112.626 2.53136C114.561 4.16676 115.855 6.43498 116.278 8.93361L116.279 8.94202L120.052 31.571C120.356 33.2266 120.245 34.9325 119.729 36.5362C119.196 38.189 118.251 39.6785 116.982 40.8638L116.902 40.939L116.819 41.0118C112.276 45.0126 106.427 47.2164 100.373 47.209L100.3 47.2089L100.227 47.2074C93.4323 47.0664 86.9201 44.4621 81.9019 39.8789L79.7825 37.9433L75.9883 5.46174e-08ZM92.563 15L94.0984 30.3538C96.0126 31.503 98.203 32.1489 100.463 32.2088C101.972 32.1998 103.448 31.8438 104.78 31.182L102.082 15H92.563Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M52.6172 0H91.0626L94.7965 37.3404L92.017 39.8789C86.9994 44.4615 80.4883 47.0658 73.6945 47.2074L73.5382 47.2106L73.3819 47.2074C66.5881 47.0658 60.0769 44.4615 55.0594 39.8789L52.6172 37.6484V0ZM67.6172 15V30.5629C69.4199 31.5681 71.4482 32.1378 73.5382 32.2064C75.4773 32.1428 77.3631 31.6478 79.0651 30.7739L77.4878 15H67.6172Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.1733 0H67.6178V37.6484L65.1756 39.8789C60.158 44.4615 53.6469 47.0658 46.8531 47.2074L46.6966 47.2106L46.5401 47.2074C39.7468 47.0654 33.2362 44.4612 28.219 39.8789L25.4395 37.3404L29.1733 0ZM42.7482 15L41.1709 30.7739C42.8726 31.6477 44.7582 32.1426 46.697 32.2064C48.7869 32.1378 50.8151 31.5681 52.6178 30.5629V15H42.7482Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5293 9.53674e-07L43.4604 0V7.87898L40.4537 37.9532L38.3342 39.8889C33.3167 44.4715 26.8055 47.0758 20.0117 47.2174L19.9363 47.2189L19.8608 47.219C13.8097 47.2234 7.96495 45.0198 3.42289 41.0216L3.3397 40.9484L3.25872 40.8728C1.98987 39.6875 1.04459 38.198 0.512411 36.5452C-0.00397137 34.9416 -0.114838 33.2357 0.188591 31.5802L3.96141 8.95026C4.38017 6.44969 5.67158 4.17845 7.60632 2.53986C9.54106 0.901277 11.9939 0.00138807 14.5293 9.53674e-07ZM18.1598 15L15.4606 31.1906C16.7928 31.8523 18.2676 32.2087 19.7766 32.2188C22.0354 32.1583 24.2245 31.5124 26.1376 30.3638L27.6737 15H18.1598Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_248">
				<rect width="120.255" height="111.628" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
)

export const RealtorSvg = () => (
	<svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1_243)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M55.8137 63.314C50.6841 63.314 45.7646 65.3517 42.1375 68.9788C38.5104 72.606 36.4727 77.5254 36.4727 82.655V88.023C36.4727 92.1651 33.1148 95.523 28.9727 95.523C24.8305 95.523 21.4727 92.1651 21.4727 88.023V82.655C21.4727 73.5472 25.0907 64.8124 31.5309 58.3722C37.9711 51.932 46.7059 48.314 55.8137 48.314C64.9215 48.314 73.6562 51.932 80.0964 58.3722C86.5366 64.8124 90.1547 73.5472 90.1547 82.655V88.023C90.1547 92.1651 86.7968 95.523 82.6547 95.523C78.5125 95.523 75.1547 92.1651 75.1547 88.023V82.655C75.1547 77.5254 73.117 72.606 69.4898 68.9788C65.8627 65.3517 60.9432 63.314 55.8137 63.314Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.7868 17.9064C51.0994 16.1222 55.8441 15.6568 60.4212 16.5689C64.9983 17.481 69.2022 19.7297 72.5014 23.0307C75.8006 26.3318 78.0469 30.5369 78.9565 35.1145C79.866 39.6921 79.3979 44.4366 77.6113 48.7482C75.8247 53.0597 72.7998 56.7448 68.9191 59.3374C65.0384 61.93 60.4761 63.3138 55.809 63.3138C52.7087 63.3138 49.6388 62.7029 46.7746 61.5161C43.9105 60.3292 41.3082 58.5897 39.1166 56.3968C36.925 54.204 35.1869 51.6008 34.0016 48.736C32.8164 45.8712 32.2073 42.8009 32.209 39.7006C32.2116 35.0335 33.5979 30.472 36.1927 26.5927C38.7875 22.7135 42.4742 19.6906 46.7868 17.9064ZM57.4897 31.2796C55.8218 30.9472 54.0928 31.1169 52.5212 31.767C50.9497 32.4172 49.6062 33.5187 48.6607 34.9324C47.7151 36.346 47.2099 38.0083 47.209 39.709C47.2084 40.8387 47.4303 41.9576 47.8622 43.0015C48.2941 44.0455 48.9275 44.9941 49.7262 45.7932C50.5248 46.5923 51.4731 47.2262 52.5168 47.6587C53.5605 48.0912 54.6792 48.3138 55.809 48.3138C57.5097 48.3138 59.1722 47.8095 60.5864 46.8648C62.0005 45.92 63.1028 44.5771 63.7539 43.006C64.4049 41.4348 64.5755 39.7059 64.2441 38.0378C63.9126 36.3697 63.094 34.8373 61.8918 33.6344C60.6896 32.4314 59.1576 31.612 57.4897 31.2796Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 15V96.628H96.628V15H15ZM3.14011 3.14011C5.15069 1.12953 7.87762 0 10.721 0H100.907C103.75 0 106.477 1.12952 108.488 3.14011C110.498 5.1507 111.628 7.87764 111.628 10.721V100.907C111.628 103.75 110.498 106.477 108.488 108.488C106.477 110.498 103.75 111.628 100.907 111.628H10.721C7.87764 111.628 5.1507 110.498 3.14011 108.488C1.12952 106.477 0 103.75 0 100.907V10.721C0 7.87762 1.12953 5.15069 3.14011 3.14011Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_243">
				<rect width="111.628" height="111.628" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
)

export const PropertySvg = () => (
	<svg width="128" height="112" viewBox="0 0 128 112" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1_241)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M63.9577 15.569L13.4173 40.8392C9.71243 42.6916 5.20738 41.1899 3.35496 37.4851C1.50254 33.7802 3.00423 29.2752 6.70906 27.4228L59.1632 1.19568C60.6521 0.452368 62.2935 0.0654297 63.9577 0.0654297C65.6218 0.0654297 67.2632 0.452368 68.7521 1.19568L121.206 27.4228C124.911 29.2752 126.413 33.7802 124.56 37.4851C122.708 41.1899 118.203 42.6916 114.498 40.8392L63.9577 15.569ZM20.8422 42.795C24.9843 42.795 28.3422 46.1528 28.3422 50.295V93.415C28.3421 93.8456 28.4269 94.2727 28.5917 94.6706C28.7564 95.0684 28.998 95.4299 29.3025 95.7345C29.6069 96.039 29.9684 96.2805 30.3663 96.4453C30.7641 96.6101 31.1905 96.695 31.6212 96.695H96.2942C96.7248 96.695 97.1512 96.6101 97.5491 96.4453C97.9469 96.2805 98.3084 96.039 98.6129 95.7345C98.9174 95.4299 99.1589 95.0684 99.3236 94.6706C99.4884 94.2727 99.5732 93.8463 99.5732 93.4156V50.295C99.5732 46.1528 102.931 42.795 107.073 42.795C111.215 42.795 114.573 46.1528 114.573 50.295V93.4143C114.573 93.4145 114.573 93.414 114.573 93.4143C114.573 95.8146 114.101 98.1922 113.182 100.41C112.264 102.628 110.917 104.643 109.22 106.341C107.523 108.038 105.507 109.385 103.29 110.303C101.072 111.222 98.6947 111.695 96.2942 111.695H31.6212C29.2206 111.695 26.8435 111.222 24.6257 110.303C22.4079 109.385 20.3927 108.038 18.6954 106.341C16.998 104.643 15.6516 102.628 14.7331 100.41C13.8147 98.1922 13.342 95.8153 13.3422 93.415C13.3422 93.4147 13.3422 93.4152 13.3422 93.415V50.295C13.3422 46.1528 16.7 42.795 20.8422 42.795ZM45.6782 77.247C45.6782 73.1048 49.036 69.747 53.1782 69.747H74.7362C78.8783 69.747 82.2362 73.1048 82.2362 77.247C82.2362 81.3891 78.8783 84.747 74.7362 84.747H53.1782C49.036 84.747 45.6782 81.3891 45.6782 77.247Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_241">
				<rect width="127.914" height="111.694" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
)
